<!--
 * @Author: 肖槿
 * @Date: 2020-06-12 15:43:48
 * @Description: 商品管理
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-13 10:17:25
 * @FilePath: \s2b-admin\src\pages\admin\shop\goods-management.vue
-->
<template>
  <div id="goods-management">
    <p class="global_tips">
      特别注意：由于小店接口限制，【编辑商品详情】动作在此管理后台进行；在小店操作可能会出现数据不同步的问题
    </p>
    <goods-management-search
      :loading="listLoading"
      :categoryList="categoryList"
      :currentTab="currentTab"
      @success="searchHandler"
      @loadData="loadData"
    />
    <a-tabs v-model="currentTab" @change="tabChangeCallback" type="card">
      <a-tab-pane v-for="v in GoodsType" :key="v.value" :tab="v.label"></a-tab-pane>
    </a-tabs>
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      :columns="currentColumns"
      :dataSource="list"
      :loading="listLoading"
      :locale="{ emptyText: '暂无信息' }"
      :pagination="tablePage"
      row-key="id"
      @change="paginationHandler"
    >
      <template slot="avatar" slot-scope="row">
        <my-image v-if="row.shuffling_figure_url" :imgUrl="row.shuffling_figure_url"></my-image>
      </template>
      <template slot="id-name" slot-scope="row">
        <div>
          <p>{{ row.out_spu_id||'' }}</p>
          <p>{{ row.spu_name }}</p>
        </div>
      </template>
      <template slot="supply_price_range" slot-scope="row">{{
        row.supply_price_range | thousandsFormat
      }}</template>
      <template slot="sub_audit_time" slot-scope="row">{{
        row.sub_audit_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="audit_status" slot-scope="row">
        <span>{{ row.audit_status | shopMargin(AuditType) }}</span>
      </template>
      <template slot="audit_time" slot-scope="row">{{
        row.audit_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="audit_create_time" slot-scope="row">{{
        row.audit_create_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="category" slot-scope="row"
        >{{ row.first_cat_id | categoryFilter(allCategoryList) }}/{{
          row.second_cat_id | categoryFilter(allCategoryList)
        }}/{{ row.third_cat_id | categoryFilter(allCategoryList) }}</template
      >
      <template slot="spu_status" slot-scope="row">
        <span>{{ row.spu_status | shopMargin(allType) }}</span>
      </template>
      <template slot="price_action" slot-scope="row">
        <a-button
          type="primary"
          v-show="[1, 2].includes(row.audit_status)"
          size="small"
          @click="showPrice(row, true)"
          class="goods-btn"
          >查看</a-button
        >
        <a-button
          v-show="row.audit_status === 0"
          type="primary"
          size="small"
          @click="showPrice(row)"
          class="goods-btn"
          >审核</a-button
        >
      </template>
      <template slot="action" slot-scope="row">
        <a-button
          style="margin-bottom: 5px"
          v-if="[3, 6].includes(row.spu_status)"
          type="primary"
          size="small"
          @click="showDetail(row)"
          class="goods-btn"
          >上架</a-button
        >
        <a-button
          style="margin-bottom: 5px"
          v-if="[7].includes(row.spu_status)"
          type="primary"
          size="small"
          @click="showDetail(row)"
          class="goods-btn"
          >编辑</a-button
        >
        <a-button
          v-if="[1].includes(row.spu_status)"
          type="primary"
          size="small"
          @click="showDetail(row)"
          class="goods-btn"
          >初审</a-button
        >
        <a-button
          v-if="[3, 6].includes(row.spu_status)"
          type="primary"
          size="small"
          class="goods-btn"
          @click="
            cancelAuditVisible = true;
            cancelIdx = row;
          "
          >撤销审核</a-button
        >
        <a-button v-auth="'shop_goods_manage_list'" size="small" @click="showDetail(row, true)"
          >查看</a-button
        >
      </template>
      <template slot="all">
        <span>--</span>
      </template>
    </a-table>
    <a-drawer
      :title="drawerTitle"
      placement="right"
      width="1100"
      :visible="merchanDrawerVisiable"
      @close="handlemerchantInfoDrawerClose"
    >
      <div>
        <goods-audit
          ref="goodsAudit"
          :shopList="shopList"
          :allCategoryList="allCategoryList"
          :currentTab="currentTab"
          @close="handlemerchantInfoDrawerClose"
          @submit="successHandler"
        />
      </div>
    </a-drawer>
    <a-drawer
      :title="priceTitle"
      placement="right"
      width="850"
      :visible="priceChangeVisiable"
      @close="priceClose"
    >
      <div>
        <price-change
          ref="priceChange"
          :allCategoryList="allCategoryList"
          :currentTab="currentTab"
          @close="priceClose"
          @submit="priceSubmit"
        />
      </div>
    </a-drawer>
    <a-modal
      title="撤销审核"
      :visible="cancelAuditVisible"
      :confirm-loading="cancelAuditLoading"
      @ok="cancelAudit"
      @cancel="cancelAuditLeave"
    >
      <div>
        <span>
          <b style="color: #f50; font-weight: 400">*</b>
          撤销审核理由：
        </span>
        <a-textarea
          v-model="auditRemark"
          :maxLength="500"
          placeholder="请输入撤销上架到小店审核的理由（不超过500字）"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { goodsManagementSearch, goodsAudit, priceChange } from '@/components/admin/shop';
import { deepClone } from '@/utils/';
import { GoodsType, GoodsTable, GoodsPriceTable, AuditType } from '@/const/shop';
import {
  getExternalSpuInfo,
  putSpuInfoAudit,
  getSkuSupplyPrice,
  getSkuStockAudit,
  getCategory,
  getMerchantList,
} from '@/service/shop';
import { mapGetters } from 'vuex';
const _GoodsType = deepClone(GoodsType);
_GoodsType.splice(7, 1);
_GoodsType.unshift({
  label: '全部',
  value: 0,
});
export default {
  name: 'GoodsManagement',
  components: {
    goodsManagementSearch,
    goodsAudit,
    priceChange,
  },
  data() {
    return {
      tableScrollY: 600,
      currentTab: 0,
      queryData: {},
      cancelAuditVisible: false,
      cancelAuditLoading: false,
      auditRemark: '',
      categoryList: [],
      allCategoryList: [],
      cancelIdx: {},
      pagination: {
        page: 1,
        page_size: 10,
        total: 0,
      },
      AuditType,
      GoodsPriceTable,
      priceChangeVisiable: false,
      merchanDrawerVisiable: false,
      listLoading: false,
      shopList: [],
      allType: GoodsType,
      GoodsType: _GoodsType,
      drawerTitle: '',
      priceTitle: '',
      list: [],
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 420;
  },
  computed: {
    currentColumns() {
      let _goodsTable = deepClone(GoodsTable);
      if (this.currentTab === 111 || this.currentTab === 222) return GoodsPriceTable;
      return _goodsTable;
    },
    tablePage() {
      return {
        current: this.pagination.page,
        total: this.pagination.total,
        pageSize: this.pagination.page_size,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        hideOnSinglePage: true,
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.page}/${Math.ceil(
            total / this.pagination.page_size,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
      };
    },
    ...mapGetters(['getUserinfo']),
  },
  async mounted() {
    this.getTableData();
    this.categoryList = await this.getCategory(0);
    this.allCategoryList = await this.getCategory(-1);
    this.getShopData();
  },
  methods: {
    tabChangeCallback(val) {
      if (val === 0) {
        delete this.queryData.spu_status;
      } else if (val === 6) {
        this.queryData.spu_status = '5,6';
      } else {
        this.queryData.spu_status = val;
      }
      this.pagination.page = 1;
      this.currentTab = val;
      this.getTableData();
    },
    async loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      targetOption.children = await this.getCategory(targetOption.id);
      if (targetOption.cat_level === 1) {
        targetOption.children.forEach(v => (v.isLeaf = true));
      }
      targetOption.loading = false;
      this.categoryList = [...this.categoryList];
    },
    async getShopData() {
      const _obj = Object.assign({}, this.pagination, this.queryData);

      delete _obj.total;
      const { res } = await getMerchantList({
        page: 1,
        page_size: 100,
        shop_status: 1,
      });
      this.shopList = res.data.rows || [];
    },
    async getCategory(pid) {
      const { res } = await getCategory(pid);
      res.data.forEach(v => (v.isLeaf = false));
      return res.data;
    },
    async getTableData() {
      this.listLoading = true;
      let res = {};
      delete this.queryData.page;
      const _obj = Object.assign({}, this.pagination, this.queryData);
      delete _obj.total;
      if (this.currentTab === 0) delete _obj.spu_status;
      if (this.currentTab === 6) {
        _obj.spu_status = '5,6';
      }
      if (this.currentTab === 111) {
        delete _obj.spu_status;
        res = await getSkuSupplyPrice(_obj);
      } else if (this.currentTab === 222) {
        delete _obj.spu_status;
        res = await getSkuStockAudit(_obj);
      } else {
        res = await getExternalSpuInfo(_obj);
      }
      this.pagination.total = res.res.data.total || 0;
      this.list = res.res.data.rows || [];
      this.listLoading = false;
    },
    searchHandler(data) {
      if (Object.keys(data).length === 0) {
        this.currentTab = 0;
      }
      this.pagination.page = 1;
      this.queryData = data;
      this.getTableData();
    },
    priceClose() {
      this.priceChangeVisiable = false;
      this.$nextTick(() => {
        this.$refs.priceChange.hide();
      });
    },
    priceSubmit() {
      this.priceClose();
      this.getTableData();
    },
    handlemerchantInfoDrawerClose() {
      this.merchanDrawerVisiable = false;
      this.$nextTick(() => {
        this.$refs.goodsAudit.hide();
      });
    },
    successHandler() {
      this.handlemerchantInfoDrawerClose();
      this.getTableData();
    },
    showPrice(row, isShow) {
      if (this.currentTab === 111) {
        this.priceTitle = isShow ? '价格变更详情' : '价格变更详情';
      } else {
        this.priceTitle = isShow ? '库存变更详情' : '库存变更详情';
      }
      this.priceChangeVisiable = true;
      this.$nextTick(() => {
        this.$refs.priceChange.show(row, isShow);
      });
    },
    // 查看
    showDetail(row, isShow) {
      if (row.spu_status === 1 && !isShow) {
        this.drawerTitle = '商品初审';
      } else if ([3, 6, 7].includes(row.spu_status) && !isShow) {
        this.drawerTitle = '上架商品';
      } else if ([2, 6].includes(row.spu_status) && isShow) {
        this.drawerTitle = '审核详情';
      } else {
        this.drawerTitle = '商品详情';
      }
      this.merchanDrawerVisiable = true;
      this.$nextTick(() => {
        this.$refs.goodsAudit.show(row, isShow);
      });
    },
    cancelAuditLeave() {
      this.cancelAuditVisible = false;
      this.auditRemark = '';
    },
    async cancelAudit() {
      if (!this.auditRemark.trim()) {
        this.$message.error('撤销审核理由不能为空');
        return;
      }
      const params = {
        spu_status: this.cancelIdx.spu_status === 3 ? 2 : 5,
        audit_user_id: this.getUserinfo.id,
        audit_user_name: this.getUserinfo.username,
        spu_id: this.cancelIdx.id,
        remark: this.auditRemark,
      };
      this.cancelAuditLoading = true;
      const { res } = await putSpuInfoAudit(params);
      this.auditRemark = '';
      if (res.success) {
        this.getTableData();
        this.$message.success('提交成功');
      }
      this.cancelAuditLoading = false;
      this.cancelAuditVisible = false;
    },
    paginationHandler(params) {
      this.pagination.page = params.current;
      this.pagination.page_size = params.pageSize;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
#goods-management {
  /deep/ .ant-table-body {
    //样式穿透
    overflow-x: auto !important;
  }
  .ant-table-body {
    //样式穿透
    overflow-x: auto !important;
  }

  .avatar {
    width: 50px;
    height: 50px;
  }
  .goods-btn {
    margin-right: 10px;
  }
  .global_tips {
    border: 1px solid #ffd77c;
    border-radius: 4px;
    background: #fff9e6;
    padding: 0 10px;
    line-height: 34px;
    font-size: 12px;
    color: #ce8e00;
  }
}
</style>
